window.PageLayersManager = function(scope, rootScope, leaseEditorService, q) {
  let $scope = scope;
  let $rootScope = rootScope;
  let LeaseEditorService = leaseEditorService;
  let $q = q;

  this.addPageBreak = () => {
    let newParagraph = $rootScope.createParagraph();
    let el = getNewPagebreakElement();
    let pageBreakElement = document.createElement("page-break");
    newParagraph.parentElement.insertBefore(el, newParagraph);
    el.parentElement.insertBefore(pageBreakElement, el.nextElementSibling);

    const contentEditable = el.closest("[contenteditable]");
    contentEditable.dispatchEvent(new Event("change"));

    window.track.event(
      new InsertPageBreakEvent({
        context: $rootScope.getContext()
      })
    );
  };

  this.deletePageBreak = (event, element) => {
    const pageBreak = element || event.target.parentElement;
    const contentEditable = pageBreak.closest("[contenteditable]");

    if (!pageBreak.classList.contains("new-page-break")) {
      if (!$scope.lease.layers) {
        $scope.lease.layers = [];
      }

      let layers = [];
      if ($scope.lease.layers) {
        layers = [...$scope.lease.layers];
      } else {
        layers = [];
      }

      let redo = function(scope) {
        return function() {
          $scope.allChangesSaved = false;
          $scope.safeApply();
          var deferred = $q.defer();
          const pid = pageBreak.getAttribute("pid");
          $scope.lease.layers.push({
            type: "page-break",
            deleted: true,
            id: pid
          });
          $scope.update();
          pageBreak.classList.add("deleted-page-break");
          if (
            pageBreak.nextElementSibling &&
            pageBreak.nextElementSibling.tagName === "PAGE-BREAK"
          ) {
            pageBreak.nextElementSibling.classList.add("lp-hide");
          }
          deferred.resolve();
          $scope.allChangesSaved = true;
          $scope.safeApply();
          return deferred.promise;
        };
      };

      let undo = function(scope, pageBreak, layers) {
        return function() {
          $scope.allChangesSaved = false;
          $scope.safeApply();
          var deferred = $q.defer();
          $scope.lease.layers = layers;
          $scope.update();
          pageBreak.classList.remove("deleted-page-break");
          if (
            pageBreak.nextElementSibling &&
            pageBreak.nextElementSibling.tagName === "PAGE-BREAK"
          ) {
            pageBreak.nextElementSibling.classList.remove("lp-hide");
          }
          deferred.resolve();
          $scope.allChangesSaved = true;
          $scope.safeApply();
          return deferred.promise;
        };
      };

      window.track.event(
        new DeletePreDefinedPageBreakEvent({
          context: $rootScope.getContext()
        })
      );

      $scope.addToHistory({
        id: "remove page break",
        desc: "remove page break from org SML",
        redo: redo(scope),
        undo: undo(scope, pageBreak, layers)
      });
    } else {
      let pageBreakTag = pageBreak.nextElementSibling;
      if (pageBreakTag && pageBreakTag.tagName === "PAGE-BREAK") {
        pageBreakTag.remove(); // remove page-break tag
      }
      pageBreak.remove(); // remove br
      contentEditable.dispatchEvent(new Event("change"));

      window.track.event(
        new DeleteNewlyAddedPageBreakEvent({
          context: $rootScope.getContext()
        })
      );

      if (pageBreak.closest(".list-item-paragraph")) {
        const contenteditable = pageBreak.closest("[contenteditable]");

        if (contenteditable && LeaseEditorService.isEmpty(contenteditable)) {
          var insertedListItemId = LeaseEditorService.closestNewListItemId(
            pageBreak
          );

          scopeVar.listsDeleteListItem(false, false, insertedListItemId);

          window.track.event(
            new DeleteNewlyAddedPageBreakEvent({
              context: $rootScope.getContext()
            })
          );
        }
      }

    }
  };

  this.updatePageBreaks = () => {
    if (!$scope.lease.layers) {
      return;
    }
    for (let index = 0; index < $scope.lease.layers.length; ++index) {
      let layer = $scope.lease.layers[index];
      if (layer.type === "page-break") {
        if (layer.deleted) {
          let pbNode = document.querySelector(`br[pid="${layer.id}"]`);

          if (pbNode) {
            pbNode.classList.add("deleted-page-break");
          }
        }
      }
    }
  };

  this.getNextLayerId = () => {
    if (!$scope.lease.layers || $scope.lease.layers.length == 0) {
      return 3000000;
    }

    let max = 0;
    for (let index = 0; index < $scope.lease.layers.length; ++index) {
      let id = parseInt($scope.lease.layers[index].id);
      if (id > max) {
        max = id;
      }
    }
    return max + 100;
  };

  this.addRemoveBtnToPageBreak = el => {
    if (el.getAttribute("pid") || el.classList.contains("new-page-break")) {
      let removeEl = document.createElement("div");
      removeEl.classList.add("page-break__remove", "remove-on-download");
      removeEl.addEventListener("click", pageLayersManager.deletePageBreak, [
        this
      ]);
      el.appendChild(removeEl);
    }
  };

  function getNewPagebreakElement() {
    let el = document.createElement("br");

    el.setAttribute(
      "style",
      "page-break-before:always; clear:both;position:relative;"
    );
    el.setAttribute("class", "new-page-break");

    if (!window.isDownload) {
      pageLayersManager.addRemoveBtnToPageBreak(el);
    }
    return el;
  }

  this.addOutlineAndListItem = (outline, listItem, isNewListItem) => {
    let currentUndoIndex = $scope.histIndex;
    const outlineId = this.addOutline(outline);
    let itemObject = {
      level: listItem,
      outline: outline,
      headerFreeText: $scope.getNextFreeTextValue(),
      bodyFreeText: $scope.getNextFreeTextValue(),
      afterFreeText: outlineId,
      isNewListItem: isNewListItem,
      fromUI: false
    };
    $scope.listsNewListItem(null, itemObject, null, null, true);
    $scope.histRegisterBatch(
      $scope.histStack.length - 1,
      $scope.histIndex - currentUndoIndex
    );

    window.track.event(
      new InsertOutlineAndListItemEvent({
        level: listItem,
        outline: outline,
        context: $rootScope.getContext()
      })
    );
  };

  this.createOutlineObject = (anchor, outline, newOutlineId, index) => {
    newOutlineId = newOutlineId ? newOutlineId : this.getNextLayerId();

    return {
      type: "outline",
      anchor: anchor,
      id: newOutlineId,
      index: index,
      data: { outline: outline }
    };
  };

  this.addOutline = outline => {
    if (!$scope.lease.layers) {
      $scope.lease.layers = [];
    }
    let currentLayers = [...$scope.lease.layers];
    let newOutlineId;
    let topAnchor;
    let index = 0;

    let outlines = Object.keys(listItemsManager.listLevelsMap);
    if (outlines.indexOf(outline) !== -1) {
      let editedNode = $scope.currentlyEditedNode;
      if (editedNode) {
        topAnchor = editedNode.getTopAnchor();
        newOutlineId = this.getNextLayerId();
        index = topAnchor.getNodeOccurrenceIndexByAttribute("pid");
      }
    }

    let redo = function(outline, newOutlineId, topAnchor, $scope) {
      return function() {
        $scope.allChangesSaved = false;
        $scope.safeApply();
        var deferred = $q.defer();
        addOutlineToDom(outline, newOutlineId, topAnchor);

        $scope.lease.layers.push(
          pageLayersManager.createOutlineObject(
            topAnchor.getAttribute("pid"),
            outline,
            newOutlineId,
            index
          )
        );
        $scope.update();
        $scope.allChangesSaved = true;
        $scope.safeApply();
        deferred.resolve();
        return deferred.promise;
      };
    };

    let undo = function(currentLayers, newOutlineId, $scope) {
      return function() {
        $scope.allChangesSaved = false;
        $scope.safeApply();
        var deferred = $q.defer();
        $scope.lease.layers = currentLayers;
        let outlineElement = document.querySelector(
          'outline[pid="' + newOutlineId + '"]'
        );
        if (outlineElement) {
          outlineElement.remove();
        }
        outlineElement = document.querySelector(
          'outline-end[pid="' + newOutlineId + '"]'
        );
        if (outlineElement) {
          outlineElement.remove();
        }
        $scope.update();
        $scope.allChangesSaved = true;
        $scope.safeApply();
        deferred.resolve();
        return deferred.promise;
      };
    };

    if (topAnchor && newOutlineId && outline) {
      scopeVar.addToHistory({
        id: "insert outline",
        desc: "add new outline with id : " + newOutlineId,
        redo: redo(outline, newOutlineId, topAnchor, $scope, index),
        undo: undo(currentLayers, newOutlineId, $scope)
      });
    }

    return newOutlineId;
  };

  this.createOutlineElement = (outlineName, id) => {
    const template = document.createElement("dummy");
    let outlineDom = document.createElement("outline");
    let outlineEndDom = document.createElement("outline-end");
    outlineDom.setAttribute("name", outlineName);
    outlineDom.setAttribute("pid", id);
    outlineEndDom.setAttribute("name", outlineName);
    outlineEndDom.setAttribute("pid", id);
    template.appendChild(outlineDom);
    template.appendChild(outlineEndDom);
    return template;
  };

  function addOutlineToDom(outlineName, id, topAnchor) {
    if(!topAnchor){
      return;
    }
    let template = this.createOutlineElement(outlineName, id);
    while (template.children.length !== 0) {
      topAnchor.parentElement.insertBefore(
        template.children[template.children.length - 1],
        topAnchor.nextElementSibling
      );
    }

    listItemsManager.addSectionEndForOutlineEnd();
  }

  addOutlineToDom = addOutlineToDom.bind(this);


  

  this.getByAnchorId = (id)=>{
    if (!$scope.lease.layers || $scope.lease.layers.length < 1) {
      return [];
    }
    return lease.layers.filter(item => item.anchor == id);
  }

  this.updateOutlineById = id => {
    if (!$scope.lease.layers || $scope.lease.layers.length < 1) {
      return;
    }

    const layerData = lease.layers.filter(item => item.id === id);
    const  layer = layerData.length == 1 ? layerData[0] : null;

    if(layer){
      let elementIndex = layer.index ? layer.index : 0;
      if (layer.type === "outline") {
        let anchor = document.querySelectorWithInstance(
          `[pid="${layer.anchor}"]`
        );
        let id = layer.id;
        addOutlineToDom(layer.data.outline, id, anchor);
      }
    }
  };

  $scope.addPageBreak = this.addPageBreak;
  $scope.addOutlineAndListItem = this.addOutlineAndListItem;
};
